<template>
  <div id="b2c" class="main-body">
    <div id="services-front" class="b2b-bg">
      <h1 class="zoomIn">Business-to-Business (B2B)</h1>
    </div>

    <div id="content">
      <h1 class="fadeIn">
        Introducing B2BPro: Your Premier Partner for Cold Chain Delivery and
        More!
      </h1>
      <p class="fadeIn">
        Welcome to B2BPro, where we specialize in keeping your business moving
        smoothly and efficiently. With our cold chain delivery services, we
        ensure that your temperature-sensitive products reach their destination
        in pristine condition, every time. But that's not all – at B2BPro, we go
        beyond just delivery. We offer comprehensive solutions tailored to your
        B2B needs, including freight forwarding and chartered truck services.
        Whether you're shipping perishable goods, bulk cargo, or specialized
        equipment, we've got the expertise and resources to handle it with care.
        Trust B2BPro to be your reliable partner in logistics, so you can focus
        on what you do best – growing your business. Experience the difference
        with B2BPro today!
      </p>
    </div>

    <visit-system
      name="B2BPRO"
      link="https://b2bpro.golog.my/login"
      :is-b2b="true"
      class="fadeIn"
    />
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import VisitSystem from "@/components/VisitSystem.vue";

export default {
  components: { VisitSystem },
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.5);
    this.setupIntersectionObserver(".fadeIn", 0.1);
  },
};
</script>
